.header_sn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.header__logo_sn {
  margin: 0 0 0 104px;
  font-family: 'Roboto-slab', Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  user-select: none;
  color: black;
}

.header__menu_sn {
  display: flex;
  align-items: center;
  margin: 0 104px 0 0;
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.header__hamburger-menu-btn_sn{
  display: none;
}


.header__button_sn {
  border: none;
  background: none;
  margin: 0 0 0 44px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  height: 80px;
  transition: ease 0.2s all;
}

.header__button-loggedin_active_sn {
  border: none;
  background: none;
  margin: 0 0 0 44px;
  text-decoration: none;
  color: black;
  border-bottom: 2px solid black;
  cursor: pointer;
  height: 80px;
  transition: ease 0.2s all;
  display: block;
}

.header__button-loggedin_sn {
  border: none;
  background: none;
  margin: 0 0 0 44px;
  text-decoration: none;
  color: black;
  border-bottom: 2px solid black;
  cursor: pointer;
  height: 80px;
  transition: ease 0.2s all;
  display: none;
}

.header__user-button_sn {
  border: 1px solid #000000;
  border-radius: 100px;
  background: none;
  margin: 0 0 0 42px;
  text-decoration: none;
  color: black;
  width: 176px;
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logout-button_sn{
  margin: 0 0 0 6px;
  padding: 0;
  display: none;
}

.header__logout-button_active_sn {
  background-image: url('../../images/logout.svg');
  width: 24px;
  height: 24px;
  margin: 0 0 0 6px;
  padding: 0;
  display: block;
}

@media screen and (max-width:834px) {
  .header_sn {
    height: 66px;
  }

  .header__menu_sn {
    margin: 0 52px 0 0;
  }

  .header__logo_sn {
    margin: 0 0 0 40px;
  }

  .header__user-button_sn {
    width: 152px;
    height: 40px;
  }

  .header__button_sn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 66px;
  }

  .header__button-loggedin_active_sn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 66px;
  }
}


@media screen and (max-width:610px) {
  .header_sn {
    height: 56px;
    color: white;
  }

  .header__mobile_sn {
    background-color: #1A1B22;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    position: relative;
  }

  .header__inactive_sn {
    opacity: 0;
  }

  .header__mobile-alignment_sn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid blue; */
    padding:0;
    /* margin: 9px 0 0 0; */
  }

  .header__logo_sn {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 0 16px;
  }

  .header__menu_sn {
    display: none;
  }

  .header__menu-mobile_sn {
    flex-direction: column;
    background-color: #1A1B22;
    width: 100%;
    z-index: 2;
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.35);
  }

  .header__user-button_sn {
    margin: 22px auto 24px auto;
    width: 288px;
    height: 56px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 56px;
    color: white;
    border-color: white;
  }

  .header__button_sn {
    margin: 16px auto 0 auto;
    border: none;
    width: 288px;
    height: 56px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 56px;
    text-align: left;
    color: white;
  }

  .header__logout-button_active_sn {
    background-image: url('../../images/logout-white.svg');
    width: 24px;
    height: 24px;
  }

  .header__button-loggedin_active_sn {
    margin: 16px auto 0 auto;
    border: none;
    width: 288px;
    height: 56px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 56px;
    text-align: left;
    color: white;
  }

  .header__hamburger-menu-btn_sn {
    background-image: url('../../images/sn-hamburger.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 24px;
    height: 24px;
    margin: 16px 16px 16px 0;
    display: block;
  }

  .header__hamburger-menu-close-btn_sn {
    background-image: url('../../images/close.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    width: 24px;
    height: 24px;
    margin: 16px 16px 16px 0;
    display: block;
  }


  .main__mobile-overlay_sn {
    display: none;
  }

  .main__mobile-overlay_active_sn {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}
