.preloader {
  display: none;
}

.preloader_active {
  display: flex;
  flex-direction: column;
  background-color: #F5F6F7;
}

.preloader__circle-preloader {
	display: block;
	width: 74px;
	height: 74px;
	border: 1px solid #1a1b22b1;
	border-bottom-color: rgba(215, 212, 212, 0);
  border-top-color: rgba(215, 212, 212, 0.387);
  border-right-color: rgba(215, 212, 212, 0);
	border-radius: 50%;
	margin: 80px auto 0 auto;
	animation: spin .75s infinite linear;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.preloader__text {
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #B6BCBF;
  margin: 24px auto 80px auto;
}
