@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.saved-news {
  width: 100%;
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0 auto;
}

.saved-news__content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: #F5F6F7;
}

.saved-news__text-content {
  /* margin: 0 17px 0 17px; */
  margin: 0 auto 0 auto;
  background-color: white;
  /* width: 90%; */
  /* padding-left: 104px; */
  width: 100%;
}

.saved-news__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(26, 27, 34, 0.5);
  margin: 40px 0 0 104px;
}

.saved-news__title {
  font-family: 'Roboto-slab', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1A1B22;
  margin: 30px 0 30px 104px;
  /* width: 100%; */
}

.saved-news__keywords {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
  margin: 0 0 56px 104px;
}

.saved-news__keywords-bold {
  font-weight: 700;
}

@media screen and (max-width:900px) {
  .saved-news__content {
    margin: 0 0 0 0;
  }
  .saved-news__subtitle {
    margin-left: 15px;
  }
  .saved-news__title {
    margin-left: 15px;
  }
  .saved-news__keywords {
    margin-left: 15px;
  }
}
@media screen and (max-width:768px) {
  .saved-news__subtitle {
    margin-left: 40px;
  }
  .saved-news__title {
    margin-left: 40px;
  }
  .saved-news__keywords {
    margin-left: 40px;
  }
}

@media screen and (max-width:490px) {
  .saved-news__content {
    margin: 0 auto;
  }

  .saved-news__text-content {
    width: 100%;
    margin: 0 auto;
  }

  .saved-news__title {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    margin: 16px 16px 50px 16px;
  }
  .saved-news__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    margin: 8px 16px 0 16px;
  }

  .saved-news__keywords {
    margin: 0 16px 32px 16px;
  }

}
