.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: relative;
  font-family: 'Roboto', Arial, sans-serif;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.25s, opacity 0.25s linear;
  display: flex;
  justify-content: center;
}

.popup_open {
  /* display: block; */
  visibility: visible;
  opacity: 1;
  transition: ease 0.25s all;
}

.popup__close-btn {
  display: absolute;
  margin: 0 0 0 100%;
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: transparent;
  background-image: url('../../images/close.svg');
  border: none;
  cursor: pointer;
  z-index: 50;
}

.popup__title {
  text-align: left;
  width: 100%;
  margin: 0 0 22px 0;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #1A1B22;
}

.popup__container {
  max-width: 430px;
  margin: 207px 0 0 0;
  position: absolute;
  width: 100%;
  top: 100%;
  height: 0;
  /* overflow: hidden; */
  transition: all 0.5s;
}

.popup__container_slide {
  top: 0;
  height: 100%;
}

.popup__form-container {
  background-color: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 34px 36px 16px 36px;
  width: 358px;
  max-width: 358px;
}

.popup__input-label {
  width: 100%;
  text-align: left;
  color: #2F71E5;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0 0 9px 0;
}

.popup__input {
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3px 3px 3px 3px;
}

.popup__input:focus {
  border-bottom: 1px solid rgba(0, 0, 0);
  outline: none;
}

.popup__input::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.2;
}

.popup__submit-btn {
  border: none;
  background-color: #2F71E5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  width: 360px;
  max-width: 360px;
  height: 64px;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 8px 0 36px 0;
  cursor: pointer;
}

.popup__submit-btn_inactive {
  display: none;
}

.popup__alt-form {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.popup__alt-form_left {
  width: 100%;
  text-align: left;
}

.popup__alt-form-text_inactive {
  display: none;
}

.popup__alt-form_inactive {
  display: none;
}

.popup__alt-form-link {
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: none;
  color: #2F71E5;
  margin: 0;
  padding: 0;
}

.popup__input-alignment {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 31px 0;
}

.popup__input-err {
  display: none;
  /* display: block; */
  margin: 3px 0 0 0;
  padding: 0;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
}

.popup__input-err_active {
  display: block;
}

@media screen and (max-width:1250px) {
  /* .popup__container {
    margin: 207px 0 0 30%;

  } */


}

@media screen and (max-width:834px) {
  /* .popup__container {
    margin: 207px 0 0 24%;

  } */

}

@media screen and (max-width:768px) {
  /* .popup__container {
    margin: 207px 0 0 15%;

  } */

}
@media screen and (max-width:530px) {
  /* .popup__container {
    margin: 207px 0 0 5%;

  } */

}

@media screen and (max-width: 490px) {
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Roboto', Arial, sans-serif;
    visibility: hidden;
    opacity: 0;
  }

  .popup_open {
    visibility: visible;
    opacity: 1;
  }

  .popup__container {
    width: 100%;
    height: 100%;
    margin: 56px auto 0 auto;
  }
  .popup__close-btn {
    margin: 16px 16px 16px 0;
    top: 0;
    right: 0;
    position: fixed;
    width: 24px;
    height: 24px;
    z-index: 50;
    background-size: contain;
  }

  .popup__form-container {
    height: 100%;
  }

  .popup__form {
    width: 288px;
    margin: 16px 0 0 0;
  }

  .popup__submit-btn {
    width: 100%;
  }

  .popup__form-container {
    background-color: white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
