.cards-list__card {
  width: 400px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  height: 576px;
  text-decoration: none;
  /* margin: 8px 8px 8px 8px; */
  margin: 0;
  border-radius: 15px;
  transition: ease 0.3s all;
  transform: scale(100%);
  box-shadow: 0 30px 58px rgba(8, 29, 45, 0);
  background-color: white;
}

/* .cards-list__card_del {
  transform: scale(0);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
}
.cards-list__card_deleted {
 display: none;
} */

.cards-list__card_del {
  display: none;
}

.cards-list__card:after {

  transition: opacity 0.1s ease-in-out;
  opacity: 0;
}

.cards-list__card:hover {
  box-shadow: 0 30px 58px rgba(8, 29, 45, 0.1);
}

.cards-list__card:hover::afrer {
  opacity: 1;
}

.cards-list__article-link {
  text-decoration: none;
}
.cards-list__card-image {
  border-radius: 15px 15px 0 0;
  margin: 0 0 20px 0;
  width: 400px;
  height: 272px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  object-fit: cover;
}

.cards-list__card-content {
  max-width: 352px;
  margin: 0 auto;
}

.cards-list__card-date {
  margin: 0;
  font-family: Source Sans Pro, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #B6BCBF;
}

.cards-list__card-title {
  font-family: 'Roboto-slab', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #1A1B22;
  margin: 12px 0 16px 0;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cards-list__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1A1B22;
  margin: 0 0 38px 0;
  /* height: 110px; */
  height: 90px;
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.cards-list__card-source {
  font-family: 'Roboto-slab', Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #B6BCBF;
  margin: 0 0 24px 0;
  width: 100%;
}

.cards-list__button-container {
  position: absolute;
}

.cards-list__save-btn {
  position: absolute;
  margin: 24px 24px 208px 336px;
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 8px;
  background-image: url('../../images/save-article-inactive.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: ease 0.2s all;
}

.cards-list__save-btn_active {
  background-image: url('../../images/save-article-active.svg');
  position: absolute;
  margin: 24px 24px 208px 336px;
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: ease 0.2s all;
}

.cards-list__save-btn_marked {
  background-image: url('../../images/save-article-marked.svg');
  position: absolute;
  margin: 24px 24px 208px 336px;
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: ease 0.2s all;
}

.cards-list__delete-btn {
  background-image: url('../../images/trash.svg');
  position: absolute;
  margin: 24px 24px 208px 336px;
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: ease 0.2s all;
}
.cards-list__delete-btn_active {
  background-image: url('../../images/trash_active.svg');
}

.cards-list__login-overlay {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 24px 5px 0 149px;
  background-color: white;
  width: 182px;
  height: 40px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.cards-list__login-overlay_active {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin: 24px 5px 0 149px;
  background-color: white;
  width: 182px;
  height: 40px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.cards-list__login-overlay_loggedin {
  width: 159px;
  margin: 24px 5px 0 172px;
}

.cards-list__login-overlay_keyword {
  width: 80px;
  margin: 24px 5px 0 24px;
}

@media screen and (max-width: 1250px) {
  .cards-list__card {
    width: 224px;
    max-width: 224px;
    height: 420px;
  }

  .cards-list__card-image {
    margin: 0 0 16px 0;
    width: 224px;
    height: 150px;
    object-fit: cover;
  }

  .cards-list__card-content {
    width: 192px;
    max-width: 192px;
  }

  .cards-list__card-title {
    font-size: 22px;
    line-height: 24px;
    margin: 12px 0 10px 0;
    height: 72px;
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .cards-list__card-text {
    margin: 14px 0 12px 0;
    height: 88px;
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .cards-list__card-source {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 16px 0;
  }

  .cards-list__save-btn {
    margin: 8px 8px 102px 176px;
  }

  .cards-list__login-overlay {
    margin: 8px 8px 102px 43px;
    width: 130px;
  }
  .cards-list__login-overlay {
    margin: 8px 8px 102px 40px;
  }

  .cards-list__login-overlay_keyword {
    width: 80px;
    margin: 8px 5px 0 8px;
  }

  .cards-list__delete-btn {
    margin: 8px 8px 140px 178px;
  }

  .cards-list__save-btn_marked {
    margin: 8px 8px 140px 178px;
  }

  .cards-list__login-overlay_loggedin {
    display: none;
  }

  .cards-list__save-btn_active {
    margin: 8px 8px 102px 176px;
  }
 }

@media screen and (max-width: 768px) {

  .cards-list__title {
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    margin: 40px 0 0 40px;
  }
  .cards-list__login-overlay {
    margin: 16px 8px 102px 98px;
  }
}


@media screen and (max-width: 758px) {

  .cards-list__title {
    width: 288px;
    margin: 32px auto 58px auto;
  }

  .cards-list__card {
    margin: 0 auto 10px auto;
    width: 288px;
    max-width: 288px;
    height: 450px;
  }

  .cards-list__card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
  }

  .cards-list__btn {
    height: 56px;
  }

  .cards-list__card-content {
    width: 256px;
    max-width: 256px;
  }
  .cards-list__card-title {
    margin: 10px 0 14px 0;
    -webkit-line-clamp: 2;
    max-height: 48px;
  }

  .cards-list__card-source {
    height: 20px;
    margin: 0;
  }
  .cards-list__card-text {
   margin: 20px 0 55px 0;
  }

  .cards-list__save-btn {
    margin: 16px 16px 140px 232px;
  }

  .cards-list__login-overlay_keyword {
    width: 80px;
    margin: 16px 5px 0 24px;
  }

  .cards-list__delete-btn {
    margin: 16px 16px 140px 232px;
  }

  .cards-list__save-btn_marked {
    margin: 16px 16px 140px 232px;
  }

}
