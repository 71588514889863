.no-search-result {
  display: none;
  background-color: #F5F6F7;
}

.no-search-result_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-search-result__icon {
  width: 96px;
  height: 96px;
  margin: 80px 0 24px 0;
}

.no-search-result__title {
  font-family: 'Roboto-slab', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #1A1B22;
  margin: 0 0 16px 0;
}

.no-search-result__text {
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #B6BCBF;
  margin: 0 0 80px 0;
  max-width: 250px;
}
