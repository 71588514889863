@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.main {
  width: 100%;
  margin: 0 auto;
  padding: 0 0;
  font-family: 'Roboto', Arial, sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
}

.main_fixed {
  position: fixed;
  max-width: 1440px;
}

.main__container {
  height: 576px;
  background-image: url('../../images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.main__content {
  max-width: 608px;
  margin: 80px auto 0 auto;
}

.main__title {
  font-family: 'Roboto-slab', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 64px;
  margin: 0 0 32px 0;
}

.main__text{
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

@media screen and (max-width: 1420px) {
  .main_fixed {
    margin: 0 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .main {
    width: 100%;
  }

  .main__title {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 40px;
  margin: 0 0 16px 0;
  }

  .main__content {
    max-width: 448px;
    margin: 38px auto 0 auto;
  }

  .main__container {
    height: 400px;
  }
}

@media screen and (max-width:700px) {
  .main_fixed {
    margin: 0 0 0 0;
    width: 100%;
  }
}

@media screen and (max-width: 530px) {
  .main__container {
    background-image: url('../../images/background_mobile.png');
    background-size: cover;
    background-position: center;
    min-height: 536px;
  }
}

@media screen and (max-width: 490px) {

  .main {
    max-width: 490px;
  }

  .main_fixed {
    margin: 0 0 0 0;
    width: 100%;
  }


  .main__content {
    max-width: 285px;
    margin: 30px auto 0 auto;
  }
  .main__title {
    margin: 0 0 16px 0;
  }
}
