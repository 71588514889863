.about {
  width: 100%;
  height: 624px;
  display: flex;
  margin: 0 auto;
  color: black;
}

.about__image {
  width: 464px;
  height: 464px;
  margin: 80px 0 80px 104px;
  border-radius: 1000px;
  background-position: center;
  background-image: url('../../images/about.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.1);
}

.about__content {
  max-width: 600px;
  height: 200px;
  margin: 131px 40px 0 56px;
}

.about__title {
  margin: 0 0 24px 0;
  font-family: 'Roboto-slab', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
}

.about__text {
  margin: 0 0 24px 0;
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 1000px) {
  .about__image {
    max-width: 350px;
    height: 350px;
  }
 }

@media screen and (max-width: 834px) {
  .about {
    height: 354px;
  }

  .about__image {
    max-width: 232px;
    height: 232px;
    margin: 61px 0 61px 40px;
  }

.about__content {
   max-width: 424px;
   margin: 40px 40px 0 32px;
  }

  .about__title {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 16px 0;
  }
  .about__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 690px) {
  .about {
    min-height: 752px;
    flex-direction: column;
    box-sizing: border-box;
  }

  .about__image {
    margin: 32px auto 24px auto;
    width: 272px;
  }

  .about__content {
    margin: 0 auto;
    width: 288px;
  }
}
